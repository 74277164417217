var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-contact" },
    [
      _vm.page === 1
        ? _c("InputForm", {
            attrs: { categories: _vm.formattedCategories },
            on: { post: _vm.postForm },
          })
        : _c("Complete"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }