<template>
  <div class="p-contact">
    <InputForm
      v-if="page === 1"
      :categories="formattedCategories"
      @post="postForm" />
    <Complete v-else />
  </div>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';

export default {
  components: {
    InputForm: () => import('@/components/contact/InputForm.vue'),
    Complete: () => import('@/components/contact/Complete.vue')
  },

  data: () => ({
    page: 1,
    categories: []
  }),

  computed: {
    formattedCategories() {
      return this.categories.map((item) => ({
        label: item.category,
        value: item.id
      }));
    }
  },

  created() {
    this.fetchCategoires();
  },

  methods: {
    /**
     * お問い合わせカテゴリ一覧を取得
     */
    async fetchCategoires() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('contact').getCategories();
        this.categories.push(...data.category);
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    /**
     * お問い合わせ内容の送信
     *
     * @param {{ mail: string; contact_id: number; content: string; }} payload
     */
    async postForm(payload) {
      if (this.$_loading_state) return;

      try {
        this.$_loading_start();

        await this.$repositories('contact').post(payload);
        this.page++; // 完了画面を表示
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    }
  }
};
</script>
